<template>
  <v-dialog
    :value="true"
    @click:outside="close()"
    persistent
    scrollable
    max-width="500"
  >
    <v-card
      ><v-system-bar window color="success">
        Abwesenheiten für Sonderveranstaltungen erfassen
      </v-system-bar>
      <v-list>
        <v-list-item>
          <PersonInput
            v-model="candidate"
            group="student"
            clearable
            label="Schüler*in hinzufügen"
            @input="add(candidate)"
          />
        </v-list-item>
      </v-list>
      <v-card-text>
        <v-chip
          v-for="item in students"
          :key="'s-' + item.id"
          @click:close="remove(item)"
          class="ma-1"
          close
        >
          <v-avatar left>
            <PortraitImage :value="item" />
          </v-avatar>
          <span> {{ item.firstName }} {{ item.lastName }}</span>
        </v-chip>
      </v-card-text>
      <v-list>
        <v-list-item>
          <DateInput v-model="item.date" label="Datum" />
        </v-list-item>
        <v-list-item>
          <LookupValueInput
            label="Standort"
            v-model="item.site"
            resource="common/site"
          />
        </v-list-item>
        <v-list-item>
          <PeriodInput
            :site="item.site"
            label="erste Lektion"
            v-model="item.period"
          />
        </v-list-item>
        <v-list-item>
          <PeriodInput
            :site="item.site"
            label="letzte Lektion"
            v-model="item.endPeriod"
          />
        </v-list-item>
        <v-list-item>
          <v-text-field label="Veranstaltung" v-model="item.description" />
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn @click="close()" text>abbrechen</v-btn>
        <v-spacer /><v-btn
          @click="save()"
          :loading="saving"
          text
          :disabled="this.students.length == 0"
          color="primary"
          >speichern</v-btn
        >
      </v-card-actions></v-card
    ></v-dialog
  >
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import PeriodInput from "common/components/PeriodInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";

export default defineComponent({
  components: {
    DateInput,
    PeriodInput,
    PersonInput,
    PortraitImage,
    LookupValueInput,
  },
  data() {
    return {
      candidate: {},
      students: [],
      item: {
        date: null,
        period: null,
        endPeriod: null,
        description: "",
        absent: true,
        site: { id: 1 },
      },
      saving: false,
    };
  },
  methods: {
    add(candidate) {
      if (!this.students.includes(candidate)) {
        this.students.push(candidate);
        this.candidate = {};
      }
    },
    close() {
      this.$router.push({ name: "AttendanceChecksSpecial" });
    },
    remove(candidate) {
      const index = this.students.indexOf(candidate);
      if (index >= 0) {
        this.students.splice(index, 1);
      }
    },
    async save() {
      this.saving = true;
      for await (const student of this.students) {
        await this.apiPost({
          resource: "absence/absence",
          data: { ...this.item, student: student },
        });
      }

      this.saving = false;
      this.$router.push({ name: "AttendanceChecksSpecial" });
    },
  },
});
</script>
